.tab-container {
  display: flex;
  justify-content: flex-start;
  margin: 20px 30px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  transition: color 0.3s;
}

.tab-button:hover,
.tab-button.active {
  font-weight: bold;
  color: black;
}

.tab-button:not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 768px) {
  .tab-button {
    font-size: 12px;
  }
}