.statistics {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    flex: 1;
    width: 100%;
}

.chart-container {
    width: 100%;
    /* overflow-x: auto; */
}

.chart-content {
    width: 100%;
    height: 300px;
}