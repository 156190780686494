.period-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: var(--primary-color);
  padding: 0 60px;
  height: 130px;
}

.period {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.divider {
  height: 20px;
  width: 1px;
  background-color: #ddd;
  align-self: center;
  margin: 0 10px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.subtitle {
  font-size: 36px;
  font-weight: 500;
  color: white;
}

@media (max-width: 768px) {
  .period-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 90px;
  }

  .title {
    font-size: 10px;
    font-weight: 700;
    color: white;
  }

  .subtitle {
    font-size: 15px;
    font-weight: 500;
    color: white;
  }
}
