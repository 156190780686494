.map {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 600px;
}

@media (max-width: 768px) {
  .map {
    height: 300px;
  }
}
