.banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1140px;
    height: 330px;
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .banner-container {
        width: 100%;
        height: 200px;
    }
}