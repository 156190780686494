.search-bar {
  display: flex;
  align-items: center;
  border: 2px solid var(--primary-color);
  border-radius: 15px;
  padding: 5px 10px;
  margin: 0px -100px;
  background-color: white;
}

.search-input {
  border: none;
  outline: none;
  flex: 1;
  padding: 10px;
  font-size: 16px;
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 20px;
}

.magnifier-icon {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .search-input {
    font-size: 10px;
  }
  .search-bar {
    margin: 0px -50px;
  }
  .magnifier-icon {
    width: 15px;
    height: 15px;
  }
}
