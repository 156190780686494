.footer-container {
  color: #d9d9d9;
  font-weight: 600;
}

.footer-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.info {
  margin: 0 20px;
}

@media (max-width: 768px) {
  .footer-container {
    color: #d9d9d9;
    font-size: 8px;
    font-weight: 600;
  }

  .info {
    margin: 0px 8px;
  }

  .info:first-child {
    margin-left: 0px;
  }
}
