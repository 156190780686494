.logo {
    display: flex;
    margin-left: 60px;
    height: 80px;
}

@media (max-width: 768px) {
    .logo {
        margin-left: 20px;
        align-items: flex-start;
    }

    .logo img {
        width: 120px;
    }
}