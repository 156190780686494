.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  height: 100px;
  background-color: white;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0 30px;
  height: 150px;
}

@media (max-width: 768px) {
  .header {
    justify-content: flex-start;
  }
  .footer {
    height: 100px;
  }
}
