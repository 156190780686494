.main-container {
  display: flex;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.work-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.work-count {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .work-text {
    font-size: 12px;
  }

  .work-count {
    font-size: 12px;
  }
}