.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
  margin-bottom: 50px;
  /* height: 500px; */
}

.search-container {
  display: flex;
  flex-direction: column;
  margin-top: 125px;
  margin-bottom: 125px;
}

.search-text {
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .search-text {
    font-size: 20px;
  }
}

.search-strength {
  font-weight: bold;
  position: relative;
  color: var(--primary-color);
}

.check-mark {
  content: "";
  background-image: url("../../assets/check.png");
  background-size: cover;
  width: 25px;
  height: 20px;
  position: absolute;
  top: -15px;
  right: 35px;
}

@media (max-width: 768px) {
  .check-mark {
    width: 15px;
    height: 13px;
    object-fit: contain;
    top: -10px;
    right: 20px;
  }
}
